export const i18n = {
  "de-AT": {
    page: {
      headline: "Neues Passwort",
      greetingText: "Bitte geben Sie Ihr neues Passwort ein.",
      newPasswordText: "Neues Passwort",
      confirmPasswordText: "Neues Passwort bestätigen",
      buttonLabel: "Passwort bestätigen",
      customerServiceText: "Kundenservice",
      customerServiceLink:
        "https://kundenservice.fielmann.at/hc/de-at/requests/new?ticket_form_id=360004068117",
      impressumText: "Impressum",
      impressumLink: "https://www.fielmann.at/impressum/",
      privacyPolicyText: "Datenschutz",
      privacyPolicyLink: "https://www.fielmann.at/datenschutz/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Bitte vergewissere dich, dass das Passwort und die Bestätigung übereinstimmen.",
      passwordStrength: {
        containsAtLeast:
          "Enthält mindestens %d der folgenden %d Arten von Zeichen:",
        identicalChars:
          'Nicht mehr als %d identische Zeichen hintereinander (wie "%s" nicht erlaubt)',
        nonEmpty: "Ein nicht leeres Passwort ist erforderlich",
        numbers: "Zahlen (wie 0-9)",
        lengthAtLeast: "Mindestens %d Zeichen lang",
        lowerCase: "Kleinbuchstaben (a-z)",
        shouldContain: "Sollte enthalten:",
        specialCharacters: "Sonderzeichen (wie !@#$%^&*)",
        upperCase: "Großbuchstaben (A-Z)",
      },
      successMessage: "Dein Passwort wurde erfolgreich zurückgesetzt.",
      configurationError:
        "Ein Fehler ist aufgetreten. Es scheint eine Fehlkonfiguration im Formular vorzuliegen.",
      networkError:
        "Der Server kann nicht erreicht werden; es gibt ein Problem mit dem Netzwerk.",
      timeoutError:
        "Der Server kann nicht erreicht werden; bitte versuche es erneut.",
      serverError:
        "Es gab einen Fehler beim Verarbeiten der Passwortzurücksetzung.",
      headerText: "Gib ein neues Passwort für<br />{email} ein",
      title: "Passwort ändern",
      weakPasswordError: "Das Passwort ist zu schwach.",
      passwordHistoryError: "Das Passwort wurde zuvor verwendet.",
    },
  },
  "de-CH": {
    page: {
      headline: "Neues Passwort",
      greetingText: "Bitte geben Sie Ihr neues Passwort ein.",
      newPasswordText: "Neues Passwort",
      confirmPasswordText: "Neues Passwort bestätigen",
      buttonLabel: "Passwort bestätigen",
      customerServiceText: "Kundenservice",
      customerServiceLink: "https://service.fielmann.ch/hc/de-ch/requests/new/",
      impressumText: "Impressum",
      impressumLink: "https://www.fielmann.ch/impressum/",
      privacyPolicyText: "Datenschutz",
      privacyPolicyLink: "https://www.fielmann.ch/datenschutz/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Bitte vergewissere dich, dass das Passwort und die Bestätigung übereinstimmen.",
      passwordStrength: {
        containsAtLeast:
          "Enthält mindestens %d der folgenden %d Arten von Zeichen:",
        identicalChars:
          'Nicht mehr als %d identische Zeichen hintereinander (wie "%s" nicht erlaubt)',
        nonEmpty: "Ein nicht leeres Passwort ist erforderlich",
        numbers: "Zahlen (wie 0-9)",
        lengthAtLeast: "Mindestens %d Zeichen lang",
        lowerCase: "Kleinbuchstaben (a-z)",
        shouldContain: "Sollte enthalten:",
        specialCharacters: "Sonderzeichen (wie !@#$%^&*)",
        upperCase: "Großbuchstaben (A-Z)",
      },
      successMessage: "Dein Passwort wurde erfolgreich zurückgesetzt.",
      configurationError:
        "Ein Fehler ist aufgetreten. Es scheint eine Fehlkonfiguration im Formular vorzuliegen.",
      networkError:
        "Der Server kann nicht erreicht werden; es gibt ein Problem mit dem Netzwerk.",
      timeoutError:
        "Der Server kann nicht erreicht werden; bitte versuche es erneut.",
      serverError:
        "Es gab einen Fehler beim Verarbeiten der Passwortzurücksetzung.",
      headerText: "Gib ein neues Passwort für<br />{email} ein",
      title: "Passwort ändern",
      weakPasswordError: "Das Passwort ist zu schwach.",
      passwordHistoryError: "Das Passwort wurde zuvor verwendet.",
    },
  },
  "de-DE": {
    page: {
      headline: "Neues Passwort",
      greetingText: "Bitte geben Sie Ihr neues Passwort ein.",
      newPasswordText: "Neues Passwort",
      confirmPasswordText: "Neues Passwort bestätigen",
      buttonLabel: "Passwort bestätigen",
      customerServiceText: "Kundenservice",
      customerServiceLink:
        "https://kundenservice.fielmann.de/hc/de/requests/new?ticket_form_id=23238753878929",
      impressumText: "Impressum",
      impressumLink: "https://www.fielmann.de/impressum/",
      privacyPolicyText: "Datenschutz",
      privacyPolicyLink: "https://www.fielmann.de/datenschutz/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Bitte vergewissere dich, dass das Passwort und die Bestätigung übereinstimmen.",
      passwordStrength: {
        containsAtLeast:
          "Enthält mindestens %d der folgenden %d Arten von Zeichen:",
        identicalChars:
          'Nicht mehr als %d identische Zeichen hintereinander (wie "%s" nicht erlaubt)',
        nonEmpty: "Ein nicht leeres Passwort ist erforderlich",
        numbers: "Zahlen (wie 0-9)",
        lengthAtLeast: "Mindestens %d Zeichen lang",
        lowerCase: "Kleinbuchstaben (a-z)",
        shouldContain: "Sollte enthalten:",
        specialCharacters: "Sonderzeichen (wie !@#$%^&*)",
        upperCase: "Großbuchstaben (A-Z)",
      },
      successMessage: "Dein Passwort wurde erfolgreich zurückgesetzt.",
      configurationError:
        "Ein Fehler ist aufgetreten. Es scheint eine Fehlkonfiguration im Formular vorzuliegen.",
      networkError:
        "Der Server kann nicht erreicht werden; es gibt ein Problem mit dem Netzwerk.",
      timeoutError:
        "Der Server kann nicht erreicht werden; bitte versuche es erneut.",
      serverError:
        "Es gab einen Fehler beim Verarbeiten der Passwortzurücksetzung.",
      headerText: "Gib ein neues Passwort für<br />{email} ein",
      title: "Passwort ändern",
      weakPasswordError: "Das Passwort ist zu schwach.",
      passwordHistoryError: "Das Passwort wurde zuvor verwendet.",
    },
  },
  "de-IT": {
    page: {
      headline: "Neues Passwort",
      greetingText: "Bitte geben Sie Ihr neues Passwort ein.",
      newPasswordText: "Neues Passwort",
      confirmPasswordText: "Neues Passwort bestätigen",
      buttonLabel: "Passwort bestätigen",
      customerServiceText: "Kundenservice",
      customerServiceLink: "https://service.fielmann.ch/hc/de-ch/requests/new/",
      impressumText: "Impressum",
      impressumLink: "https://www.fielmann.ch/impressum/",
      privacyPolicyText: "Datenschutz",
      privacyPolicyLink: "https://www.fielmann.ch/datenschutz/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Bitte vergewissere dich, dass das Passwort und die Bestätigung übereinstimmen.",
      passwordStrength: {
        containsAtLeast:
          "Enthält mindestens %d der folgenden %d Arten von Zeichen:",
        identicalChars:
          'Nicht mehr als %d identische Zeichen hintereinander (wie "%s" nicht erlaubt)',
        nonEmpty: "Ein nicht leeres Passwort ist erforderlich",
        numbers: "Zahlen (wie 0-9)",
        lengthAtLeast: "Mindestens %d Zeichen lang",
        lowerCase: "Kleinbuchstaben (a-z)",
        shouldContain: "Sollte enthalten:",
        specialCharacters: "Sonderzeichen (wie !@#$%^&*)",
        upperCase: "Großbuchstaben (A-Z)",
      },
      successMessage: "Dein Passwort wurde erfolgreich zurückgesetzt.",
      configurationError:
        "Ein Fehler ist aufgetreten. Es scheint eine Fehlkonfiguration im Formular vorzuliegen.",
      networkError:
        "Der Server kann nicht erreicht werden; es gibt ein Problem mit dem Netzwerk.",
      timeoutError:
        "Der Server kann nicht erreicht werden; bitte versuche es erneut.",
      serverError:
        "Es gab einen Fehler beim Verarbeiten der Passwortzurücksetzung.",
      headerText: "Gib ein neues Passwort für<br />{email} ein",
      title: "Passwort ändern",
      weakPasswordError: "Das Passwort ist zu schwach.",
      passwordHistoryError: "Das Passwort wurde zuvor verwendet.",
    },
  },
  "it-CH": {
    page: {
      headline: "Nuova password",
      greetingText: "Inserisci la tua nuova password.",
      newPasswordText: "Nuova password",
      confirmPasswordText: "Conferma la nuova password",
      buttonLabel: "Conferma password",
      customerServiceText: "Contatti",
      customerServiceLink: "https://service.fielmann.ch/hc/it-ch/requests/new/",
      impressumText: "Dati societari",
      impressumLink: "https://it.fielmann.ch/datisocietari/",
      privacyPolicyText: "Tutela dei dati",
      privacyPolicyLink: "https://it.fielmann.ch/protezionedeidati/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Assicurati che la password e la conferma siano uguali.",
      passwordStrength: {
        containsAtLeast:
          "Contiene almeno %d dei seguenti %d tipi di caratteri:",
        identicalChars:
          'Non più di %d caratteri identici di fila (come "%s" non consentito)',
        nonEmpty: "È richiesta una password non vuota",
        numbers: "Numeri (come 0-9)",
        lengthAtLeast: "Almeno %d caratteri di lunghezza",
        lowerCase: "Lettere minuscole (a-z)",
        shouldContain: "Dovrebbe contenere:",
        specialCharacters: "Caratteri speciali (come !@#$%^&*)",
        upperCase: "Lettere maiuscole (A-Z)",
      },
      successMessage: "La tua password è stata reimpostata con successo.",
      configurationError:
        "Si è verificato un errore. Sembra esserci una cattiva configurazione nel modulo.",
      networkError:
        "Il server non può essere raggiunto; c'è un problema con la rete.",
      timeoutError: "Il server non può essere raggiunto; per favore riprova.",
      serverError:
        "Si è verificato un errore durante l'elaborazione del ripristino della password.",
      headerText: "Inserisci una nuova password per<br />{email}",
      title: "Cambia password",
      weakPasswordError: "La password è troppo debole.",
      passwordHistoryError: "La password è stata utilizzata in precedenza.",
    },
  },
  "it-IT": {
    page: {
      headline: "Nuova password",
      greetingText: "Inserisci la tua nuova password.",
      newPasswordText: "Nuova password",
      confirmPasswordText: "Conferma la nuova password",
      buttonLabel: "Conferma password",
      customerServiceText: "Contatti",
      customerServiceLink: "https://service.fielmann.ch/hc/it-ch/requests/new/", // TODO: Update with actual link for Italy
      impressumText: "Dati societari",
      impressumLink: "https://it.fielmann.ch/datisocietari/", // TODO: Update with actual link for Italy
      privacyPolicyText: "Tutela dei dati",
      privacyPolicyLink: "https://it.fielmann.ch/protezionedeidati/", // TODO: Update with actual link for Italy
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Assicurati che la password e la conferma siano uguali.",
      passwordStrength: {
        containsAtLeast:
          "Contiene almeno %d dei seguenti %d tipi di caratteri:",
        identicalChars:
          'Non più di %d caratteri identici di fila (come "%s" non consentito)',
        nonEmpty: "È richiesta una password non vuota",
        numbers: "Numeri (come 0-9)",
        lengthAtLeast: "Almeno %d caratteri di lunghezza",
        lowerCase: "Lettere minuscole (a-z)",
        shouldContain: "Dovrebbe contenere:",
        specialCharacters: "Caratteri speciali (come !@#$%^&*)",
        upperCase: "Lettere maiuscole (A-Z)",
      },
      successMessage: "La tua password è stata reimpostata con successo.",
      configurationError:
        "Si è verificato un errore. Sembra esserci una cattiva configurazione nel modulo.",
      networkError:
        "Il server non può essere raggiunto; c'è un problema con la rete.",
      timeoutError: "Il server non può essere raggiunto; per favore riprova.",
      serverError:
        "Si è verificato un errore durante l'elaborazione del ripristino della password.",
      headerText: "Inserisci una nuova password per<br />{email}",
      title: "Cambia password",
      weakPasswordError: "La password è troppo debole.",
      passwordHistoryError: "La password è stata utilizzata in precedenza.",
    },
  },
  "fr-CH": {
    page: {
      headline: "Nouveau mot de passe",
      greetingText: "Veuillez entrer votre nouveau mot de passe.",
      newPasswordText: "Nouveau mot de passe",
      confirmPasswordText: "Confirmer le nouveau mot de passe",
      buttonLabel: "Confirmer le mot de passe",
      customerServiceText: "Service clièntele",
      customerServiceLink: "https://service.fielmann.ch/hc/fr-ch/requests/new/",
      impressumText: "Mentions légales",
      impressumLink: "https://fr.fielmann.ch/mentions-legales/",
      privacyPolicyText: "Protection des données",
      privacyPolicyLink: "https://fr.fielmann.ch/protectiondesdonnees/",
    },

    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError:
        "Veuillez vous assurer que le mot de passe et la confirmation sont identiques.",
      passwordStrength: {
        containsAtLeast:
          "Contient au moins %d des %d types de caractères suivants :",
        identicalChars:
          "Pas plus de %d caractères identiques d'affilée (comme \"%s\" n'est pas autorisé)",
        nonEmpty: "Un mot de passe non vide est requis",
        numbers: "Chiffres (comme 0-9)",
        lengthAtLeast: "Au moins %d caractères de long",
        lowerCase: "Lettres minuscules (a-z)",
        shouldContain: "Devrait contenir :",
        specialCharacters: "Caractères spéciaux (comme !@#$%^&*)",
        upperCase: "Lettres majuscules (A-Z)",
      },
      successMessage: "Votre mot de passe a été réinitialisé avec succès.",
      configurationError:
        "Une erreur s'est produite. Il semble y avoir une mauvaise configuration dans le formulaire.",
      networkError:
        "Le serveur est inaccessible; il y a un problème avec le réseau.",
      timeoutError: "Le serveur est inaccessible; veuillez réessayer.",
      serverError:
        "Une erreur s'est produite lors du traitement de la réinitialisation du mot de passe.",
      headerText: "Entrez un nouveau mot de passe pour<br />{email}",
      title: "Changer le mot de passe",
      weakPasswordError: "Le mot de passe est trop faible.",
      passwordHistoryError: "Le mot de passe a déjà été utilisé.",
    },
  },
  "cs-CZ": {
    page: {
      headline: "Nové heslo",
      greetingText: "Zadejte prosím své nové heslo.",
      newPasswordText: "Nové heslo",
      confirmPasswordText: "Potvrzení nového hesla",
      buttonLabel: "Potvrzení hesla",
      customerServiceText: "Zákaznický servis",
      customerServiceLink:
        "https://zakaznickyservis.fielmann.cz/hc/cs/requests/new",
      impressumText: "Impresum",
      impressumLink: "https://www.fielmann.cz/impresum/",
      privacyPolicyText: "Ochrana osobních údajů",
      privacyPolicyLink: "https://www.fielmann.cz/ochrana-udaju/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError: "Hesla se neshodují",
      passwordStrength: {
        containsAtLeast: "Obsahuje alespoň %d z následujících %d typů znaků:",
        identicalChars:
          "Ne více než %d stejných znaků za sebou (např. „%s“ není povoleno).",
        nonEmpty: "Je vyžadováno neprázdné heslo",
        numbers: "Čísla (např. 0-9)",
        lengthAtLeast: "Alespoň %d znaků dlouhé",
        lowerCase: "Malá písmena (a-z)",
        shouldContain: "Mělo by obsahovat:",
        specialCharacters: "Speciální znaky (např. !@#$%^&*)",
        upperCase: "Velká písmena (A-Z)",
      },
      successMessage: "Vaše heslo bylo úspěšně resetováno.",
      configurationError:
        "Došlo k chybě. Zdá se, že ve formuláři došlo k chybné konfiguraci.",
      networkError: "Na server se nelze dovolat, došlo k problému v síti.",
      timeoutError: "Na server se nelze dovolat; zkuste to prosím znovu.",
      serverError: "Při zpracování obnovení hesla došlo k chybě.",
      headerText: "Zadejte nové heslo pro<br />{email}",
      title: "Změna hesla",
      weakPasswordError: "Heslo je příliš slabé.",
      passwordHistoryError: "Heslo bylo použito dříve.",
    },
  },
  "pl-PL": {
    page: {
      headline: "Nowe hasło",
      greetingText: "Wprowadź nowe hasło.",
      newPasswordText: "Nowe hasło",
      confirmPasswordText: "Potwierdź nowe hasło",
      buttonLabel: "Potwierdź hasło",
      customerServiceText: "Obsługa klienta",
      customerServiceLink:
        "https://obslugaklienta.fielmann.pl/hc/pl/requests/new",
      impressumText: "Impressum",
      impressumLink: "https://www.fielmann.pl/impressum/",
      privacyPolicyText: "Polityka prywatności",
      privacyPolicyLink: "https://www.fielmann.pl/ochronadanych/",
    },
    auth0: {
      passwordPlaceholder: "",
      passwordConfirmationPlaceholder: "",
      passwordConfirmationMatchError: "Hasła nie zgadzają się ze sobą",
      passwordStrength: {
        containsAtLeast:
          "Zawiera co najmniej %d z następujących %d typów znaków:",
        identicalChars:
          "Nie więcej niż %d identycznych znaków pod rząd (np. „%s” jest niedozwolone).",
        nonEmpty: "Wymagane jest niepuste hasło",
        numbers: "Liczby (np. 0-9)",
        lengthAtLeast: "Przynajmniej %d znaków długości",
        lowerCase: "Małe litery (a-z)",
        shouldContain: "Powinno zawierać:",
        specialCharacters: "Znaki specjalne (np. !@#$%^&*)",
        upperCase: "Duże litery (A-Z)",
      },
      successMessage: "Hasło zostało pomyślnie zresetowane.",
      configurationError:
        "Wystąpił błąd. Wygląda na to, że w formularzu wystąpił błąd konfiguracji.",
      networkError:
        "Nie można uzyskać połączenia z serwerem; wystąpił problem z siecią.",
      timeoutError:
        "Nie można uzyskać połączenia z serwerem; spróbuj ponownie.",
      serverError: "Wystąpił błąd podczas resetowania hasła.",
      headerText: "Wprowadź nowe hasło dla<br />{email}",
      title: "Zmiana hasła",
      weakPasswordError: "Hasło jest zbyt słabe.",
      passwordHistoryError: "Hasło było wcześniej używane.",
    },
  },
};

export function getLang() {
  const hash = window.location.hash;
  const params = hash.substring(1);
  const urlParams = new URLSearchParams(params);

  return urlParams.get("ui_locales") || urlParams.get("lang") || "de-CH";
}

export function getAuth0Dict() {
  return i18n[getLang()].auth0;
}

export function getPageDict() {
  return i18n[getLang()].page;
}
