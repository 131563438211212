import { getAuth0Dict, getPageDict } from "./i18n";

window.onload = () => {
  const event = new CustomEvent("language-loaded", {
    detail: {
      auth0Dict: getAuth0Dict(),
      pageDict: getPageDict(),
    },
  });

  window.dispatchEvent(event);
};
